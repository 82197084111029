<template>
  <div id="app">
      <router-view />
  </div>
</template>
<script>


export default {
name: 'App',
data() {
    return {

    }
  },
  created(){
    this.isWx();
  },
  methods: {
    isWx(){
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return new Promise((resolve) => {
          wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
                localStorage.setItem('envType',1)
            } else {
                localStorage.setItem('envType',2)
            }
          });
        });
      } else {
                localStorage.setItem('envType',3)
      }
    }
  },
  mounted() {
   
  },

}
</script>

<style>

</style>
