import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router.js'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import { position } from './js/position.js'
import Vconsole from 'vconsole'
import { Swiper, SwiperSlide } from 'swiper/js/swiper.js';
// Import Swiper styles
import 'swiper/css/swiper.min.css';
// 测试和开发打开，生产不能打开
if (process.env.NODE_ENV !== 'production') { 
    // let vConsole = new Vconsole()
    // Vue.use(vConsole)
}

Vue.use(preview)
Vue.config.productionTip = false
Vue.prototype.appId = 'wxafa45f97f0d87241';
Vue.prototype.redirectUri = 'https://zh1.ky3d.com/';
Vue.prototype.exhibitionPostionXYZ = position



new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
