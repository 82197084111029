export const position = {
    37:{
        positionx: 4.89,
        positiony: 0.16,
        positionz: 5.86,  
        glbScale:2
    },
    38:{
        positionx: -32,
        positiony: 0.4,
        positionz: 36, 
        glbScale:4
    },
    39:{
        positionx: 17.38,
        positiony: 0.4,
        positionz: 14.73,  
        glbScale:0.25
    },
    40:{
        positionx: 1,
        positiony: 0.4,
        positionz: 20,  
        glbScale:3
    },
    41:{
        positionx: -24,
        positiony: 0.4,
        positionz: 7.34,  
        glbScale:0.5

    },
    42:{
        positionx: 30,
        positiony: 0.4,
        positionz: -1,
        glbScale:2
  
    },
    43:{
        positionx: 0,
        positiony: 1.26,
        positionz: 0.26,  
        glbScale:1.5

    },
    44:{            
        positionx: 17.27,
        positiony: 1.86,
        positionz: 14.5,  
        glbScale:1.25

    },
    45:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:4

    },
    46:{
        positionx: 4.89,
        positiony: 0.16,
        positionz: 5.86,  
        glbScale:2

    },
    47:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:1

    },
    48:{
        positionx: -5,
        positiony: 0,
        positionz: 6,  
        glbScale:2

    },
    49:{
        positionx: -21,
        positiony: 0,
        positionz: -13,  
        glbScale:2

    },
    50:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    51:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:1.25

    },
    52:{
        positionx: 0,
        positiony: 0,
        positionz: 20,  
        glbScale:2

    },
    53:{
        positionx: 35,
        positiony: 0,
        positionz: 16,  
        glbScale:2

    },
    54:{
        positionx: -14.39,
        positiony: 0.26,
        positionz: -11.7,  
        glbScale:2

    },
    55:{
        positionx: -17.5,
        positiony: 0,
        positionz: -1,  
        glbScale:2

    },
    56:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    57:{
        positionx: -8,
        positiony: 0,
        positionz: -2,  
        glbScale:2

    },
    58:{
        positionx: 0,
        positiony: 0.26,
        positionz: 0,  
        glbScale:6

    },
    59:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    60:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    61:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    62:{
        positionx: 12,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    63:{
        positionx: 7,
        positiony: 0.16,
        positionz: -15,  
        glbScale:2

    },
    64:{
        positionx: -17,
        positiony: 0.26,
        positionz: 22,  
        glbScale:2

    },
    65:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2

    },
    66:{
        positionx: 6,
        positiony: 0.26,
        positionz: 6,  
        glbScale:2

    },
    67:{
        positionx: -14,
        positiony: 0,
        positionz: -3,  
        glbScale:2

    },
    68:{
        positionx: 0,
        positiony: 0.16,
        positionz: 0,  
        glbScale:2

    },
    69:{
        positionx: 30,
        positiony: 0,
        positionz: 17,  
        glbScale:2

    },
    70:{
        positionx: -64,
        positiony: 0.41,
        positionz: 0,  
        glbScale:1.5

    },
    72:{
        positionx: 14,
        positiony: 0.2,
        positionz: 1.81,
        glbScale:2
    },
    73:{
        positionx: 3.34,
        positiony: 0,
        positionz: 10.85,
        glbScale:2
    },
    74:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    75:{
        positionx: 0,
        positiony: 0.87,
        positionz: 22.76,
        glbScale:2.5
    },
    76:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    77:{
        positionx: 57.48,
        positiony: 0,
        positionz: 15.08,
        glbScale:2
    },
    78:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    79:{
        positionx: -30.25,
        positiony: 0,
        positionz: 0,
        glbScale:5
    },
    80:{
        positionx: 0,
        positiony: 4.82,
        positionz: 35,
        glbScale:2
    },
    81:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    82:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    83:{
        positionx: 47.433,
        positiony: 0,
        positionz: -3.525,
        glbScale:2
    },
    84:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    85:{
        positionx: 31,
        positiony: 0,
        positionz: 16,
        glbScale:1.5
    },
    86:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:1.5
    },
    87:{
        positionx: 72,
        positiony: 0,
        positionz: 4,
        glbScale:2
    },
    88:{
        positionx: 0,
        positiony: 0.5,
        positionz: 0,
        glbScale:2
    },
    89:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    90:{
        positionx: 6.66,
        positiony: 0,
        positionz: -51.84,
        glbScale:2
    },
    91:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    92:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:1.5
    },
    93:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    94:{
        positionx: 0,
        positiony: 0,
        positionz: 10,
        glbScale:2
    },
    95:{
        positionx: -40.0,
        positiony: 0,
        positionz: 4,
        glbScale:2
    },
    96:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    97:{
        positionx: 0,
        positiony: 0,
        positionz: 0,  
        glbScale:2
    },    
    98:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    99:{
        positionx: -10,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    100:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    101:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    102:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    103:{
        positionx: 0,
        positiony: 1.26,
        positionz: 0.26,  
        glbScale:1.5
    },
    104:{
        positionx: 0,
        positiony: 0,
        positionz: 0.26,  
        glbScale:3
    },
    105:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    106:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:5
    },
    107:{
        positionx: -2.48,
        positiony: 0.34,
        positionz: 22,
        glbScale:5
    },
    108:{
        positionx: 0,
        positiony: 0,
        positionz: 0,
        glbScale:2
    },
    109:{
        positionx: 0,
        positiony: 0,
        positionz: 13.84,
        glbScale:2
    },
}