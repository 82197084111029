import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: [{
        path: '/',
        component: () => import('./Home.vue'),
        title: '元宇宙展馆'
    },{
        path: '/viewModel',
        component: () => import('./ViewModel.vue')
    },{
        path: '/loadingProgram',
        component: () => import('./components/loadingProgram.vue')
    },{
        path: '/loadingProgramNew',
        component: () => import('./components/loadingProgramNew.vue')
    },{
        path: '/bottomNav',
        component: () => import('./components/bottomNav.vue')
    },{
        path: '/personSelect',
        component: () => import('./components/personSelect.vue')
    },{
        path: '/personSelectNew',
        component: () => import('./components/personSelectNew.vue')
    },{
        path: '/personSelectPc',
        component: () => import('./components/personSelectPc.vue')
    },{
        path: '/personSelectLandScape',
        component: () => import('./components/personSelectLandScape.vue')
    },{
        path: '/navNew',
        component: () => import('./components/navNew.vue')
    },{
        path: '/popCom',
        component: () => import('./components/popCom.vue')
    },{
        path: '/nhPop',
        component: () => import('./components/nhPop.vue')
    },{
        path: '/video',
        component: () => import('./components/video.vue')
    }]

})

export default router